import MyButtonComponent from './form/my-button'
import MyInputComponent from './form/my-input';
import MyTextComponent from './form/my-text';
import MyNumberComponent from './form/my-input/my-number';
import MyPriceComponent from './form/my-input/my-price';
import MyTextareaComponent from './form/my-input/my-textarea';
import MySelectComponent from './form/my-select';
import MySelectsComponent from './form/my-select/my-selects';
import MySelectSearchComponent from './form/my-select/my-select-search';
import MyCheckboxComponent from './form/my-checkbox';
import MyListComponent from './my-list'
import MyModalComponent from './my-modal'
import MyDrawerComponent from './my-drawer'
import MyFormComponent from './form/my-form'
import MyRadioComponent from './form/my-radio'
import MyUploadComponent from './form/my-upload'
import MyUploadImgComponent from './form/my-upload/my-upload-img'
import MyUploadImgsComponent from './form/my-upload/my-upload-imgs'
import MySwitchComponent from './form/my-switch'
import MyDatePickerComponent from './form/my-datePicker'
import MyDateToDateComponent from './form/my-datePicker/my-dateToDate'
import MyTimeToTimeComponent from './form/my-datePicker/my-timeToTime'
import MyTreeSelectComponent from './form/my-treeSelect'
import MyTreeSelectsComponent from './form/my-treeSelect/my-treeSelects'
import DxTreeSelectsComponent from './form/my-treeSelect/dx-treeSelects'
import MyCascaderComponent from './form/my-cascader'
import MyEditorComponent from './form/my-editor'
import MyEchartsComponent from './my-echarts'
import PieEchartsComponent from './echarts/pieEcharts/index'
import FunnelEchartsComponent from './echarts/funnelEcharts/index'
import DoubleLineEchartsComponent from './echarts/doubleLineEcharts/index'
import myStepsComment from '@/views/components/my-steps-comment.vue';

const MyComponen = {
  install: function (Vue) {
    Vue.component('MyButton', MyButtonComponent);
    Vue.component("MyInput", MyInputComponent);
    Vue.component("MyText", MyTextComponent);
    Vue.component("MyNumber", MyNumberComponent);
    Vue.component("MyPrice", MyPriceComponent);
    Vue.component("MyTextarea", MyTextareaComponent);
    Vue.component("MySelect", MySelectComponent);
    Vue.component("MySelects", MySelectsComponent);
    Vue.component("MySelectSearch", MySelectSearchComponent);
    Vue.component("MyCheckbox", MyCheckboxComponent);
    Vue.component('MyList', MyListComponent);
    Vue.component('MyModal', MyModalComponent);
    Vue.component('MyDrawer', MyDrawerComponent);
    Vue.component('MyForm', MyFormComponent);
    Vue.component('MyRadio', MyRadioComponent);
    Vue.component('MyUpload', MyUploadComponent);
    Vue.component('MyUploadImg', MyUploadImgComponent);
    Vue.component('MyUploadImgs', MyUploadImgsComponent);
    Vue.component('MySwitch', MySwitchComponent);
    Vue.component('MyDatePicker', MyDatePickerComponent);
    Vue.component('MyDateToDate', MyDateToDateComponent);
    Vue.component('MyTimeToTime', MyTimeToTimeComponent);
    Vue.component('MyTreeSelect', MyTreeSelectComponent);
    Vue.component('MyTreeSelects', MyTreeSelectsComponent);
    Vue.component('DxTreeSelects', DxTreeSelectsComponent);
    Vue.component('MyCascader', MyCascaderComponent);
    Vue.component('MyEditor', MyEditorComponent);
    Vue.component('MyEcharts', MyEchartsComponent);
    Vue.component('PieEcharts', PieEchartsComponent);
    Vue.component('FunnelEcharts', FunnelEchartsComponent);
    Vue.component('DoubleLineEcharts', DoubleLineEchartsComponent);
    Vue.component('myStepsComment', myStepsComment)
  }
}

export default MyComponen